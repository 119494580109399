module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-jss/gatsby-browser.js'),
      options: {"plugins":[],"theme":{"typography":{"primary":"Oxanium , ui-monospace, SFMono-Regular, Menlo, \"Roboto Mono\", monospace","secondary":"Oxanium, ui-monospace, SFMono-Regular, Menlo, \"Roboto Mono\", monospace","tertiary":{"fontFamily":"Oxanium, ui-monospace, SFMono-Regular, Menlo, \"Roboto Mono\", monospace"}},"color":{"accent":0.2,"alpha":0.5,"primary":{"dark":"#bbbbbb","main":"#eeeeee","light":"#ffffff"},"secondary":{"dark":"#2d006a","main":"#6a0fa1","light":"#8613cd"},"tertiary":{"dark":"#f37921","main":"#f7a161","light":"#e1ba66"},"heading":{"main":"#f7a161"},"text":{"main":"#e1ba66"},"link":{"dark":"#6a0fa1","main":"#f7a161","light":"#e1ba66"},"background":{"dark":"#000000","main":"#f7a161","light":"#6a0fa1"}},"animation":{"time":250,"stagger":50},"dd":{}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"dawndrums","short_name":"dawndrums","start_url":"/","background_color":"#e1ba66ff","theme_color":"#e1ba66ff","display":"standalone","orientation":"portrait","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"71f02c09f73b640461bf1e532a30c006"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"C:\\dd-www\\soul3\\src\\layouts\\Template\\index.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
