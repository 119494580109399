import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import anime from 'animejs';

import { Text } from '../Text';
import { Button } from '../Button';
import { Secuence } from '../Secuence';
import { getPathLength } from '../../tools/general';
import { Animated, Animator} from "@arwes/react";
import   { Text as TextAnimated } from  "@arwes/react";
import dd_glyph from "../../../static/images/dd_glyph.svg";
import dd_logo from "../../../static/images/logo.svg";

class Component extends React.Component {
  static displayName = 'Popup';
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      showDiv: true,  
    };
  }
  static propTypes = {
    theme: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    energy: PropTypes.object.isRequired,
    audio: PropTypes.object.isRequired,
    sounds: PropTypes.object.isRequired,
    className: PropTypes.any,
    message: PropTypes.string.isRequired,
    option: PropTypes.string.isRequired,
    onOption: PropTypes.func
  };

  enter () {
    const { energy } = this.props;
    const paths = this.svgElement.querySelectorAll('path');

    anime.set(paths, {
      strokeDasharray: getPathLength
    });

    anime({
      targets: paths,
      strokeDashoffset: [getPathLength, 0],
      easing: 'linear',
      duration: energy.duration.enter
    });
  }

  exit () {
    const { energy } = this.props;
    const paths = this.svgElement.querySelectorAll('path');

    anime({
      targets: paths,
      strokeDashoffset: [0, getPathLength],
      easing: 'linear',
      duration: energy.duration.exit
    });
  }
   handleImageLoad = () => {
    this.setState({ imageLoaded: true });
  }; 
  componentDidMount() {
    // Set the visibility to true after the component mounts
    this.timer = setTimeout(() => {
      this.setState({ isVisible: true });
    }, 100); // Delay to allow for the animation to start
  }

  componentWillUnmount() {
    clearTimeout(this.timer); // Cleanup the timer
  }
  handleTransitionEnd = () => {
    this.setState({ showDiv: true });
  };
  render () {
    const {
      theme,
      classes,
      energy,
      audio,
      sounds,
      className,
      message,
      option,
      onOption,
      ...etc
    } = this.props;
const { isVisible } = this.state;
const {showDiv}= this.state;

    return (
      <div
        className={cx(classes.root, className)}
        {...etc}
      >
    <div style={{

      display: 'flex',
      justifyContent: 'center', // Center horizontally
      alignItems: 'center', // Center vertically	  
      width: '300px', // Width of the square
      height: '300px', // Height of the square
    }}>
      <div style={{
        position: 'absolute', // Position absolutely within the container
        width: '150px',
        height: 'auto',
        top: '25%',
        left: '50%', // Center horizontally
        transform: 'translateX(-50%) rotate(0deg)', // Center the image and rotate
        overflow: 'hidden', // Hide overflow
      }}>
        <img src={dd_glyph}  alt="Image 1" style={{
          width: '100%', // Make the image responsive to the container
          height: 'auto', // Maintain aspect ratio
		  transition: 'opacity  1s ease-in-out', // Transition for the clip-path
          pointerEvents: 'none', // Disable interactions
			opacity: isVisible ? '100%' : '0',		  
		  border: 'none'		  
        }} onTransitionEnd={this.handleTransitionEnd}/>
		</div>
      <div style={{
        position: 'absolute', // Position absolutely within the container
        width: '150px',
        height: 'auto',
        top: '-15%',
        left: '50%', // Center horizontally
        transform: 'translateX(-50%) rotate(0deg)', // Center the image and rotate
		filter: 'drop-shadow(0 0 1.5px rgba(243, 121, 33, 0.8))', // Brighter glow on hover
      }}>		
        <img src={dd_logo}  alt="Image 1" style={{
          width: '100%', // Make the image responsive to the container
          height: 'auto', // Maintain aspect ratio
		  transition: 'opacity 1s ease-in-out', // Transition for the clip-path
          pointerEvents: 'none', // Disable interactions
		  opacity: isVisible ? '100%' : '0',	
		  border: 'none'		  
        }} />		
      </div>
      <div style={{
        position: 'absolute',
        width: '150px',
        height: 'auto',
        top: '50%', // Center vertically
        left: '44%', // Position to the right
        transform: 'translate(-100%, -50%) rotate(90deg)', // Center the image and rotate
        overflow: 'hidden',
      }}>
        <img src={dd_glyph}  alt="Image 2" style={{
          width: '100%',
          height: 'auto',
		  transition: 'opacity  1s ease-in-out', // Transition for the clip-path	
          pointerEvents: 'none', // Disable interactions	
			opacity: isVisible ? '100%' : '0',
		  border: 'none'			  
        }} />
      </div>	

 <div className={classes.frame}>
          <svg
            className={classes.svg}
            ref={ref => (this.svgElement = ref)}
            viewBox='0 0 100 40'
            preserveAspectRatio='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            {/* left-top */}
            <path className={classes.path} d='' />
            {/* right-top */}
            <path className={classes.path} d='' />
            {/* left-bottom */}
            <path className={classes.path} d='' />
            {/* right-bottom */}
            <path className={classes.path} d='' />
          </svg>
        </div>

      	  {showDiv  && (  <div className={classes.main}>
          <Secuence>
            <div className={classes.message}>
              <Text
                audio={audio}
              > </Text>
		<Animator duration={{ enter: 2 }} onAnimationEnd >	  
		  <TextAnimated
			as="div"
			manager="stagger"
			easing="outSine"
			fixed
		  >
                {message}
			</TextAnimated>
             </Animator>
			 		<Animator duration={{ enter: 4 }} >	

		  <TextAnimated
			as="div"
			manager="decipher"
			easing="outSine"
			fixed
		  >
               DAWNDRUMS
			</TextAnimated>
             </Animator >
            </div>
            <div className={classes.options}>
              <Button
                className={classes.option}
                audio={audio}
                animation={{ animate: energy.animate }}
                onClick={onOption}
              >
                {option}
              </Button>
            </div>
          </Secuence>
        </div>		
		)}
      </div>	  

	  
      <div style={{
        position: 'absolute',
        width: '150px',
        height: 'auto',
        top: '25%', // Position at the bottom
        left: '50%', // Center horizontally
        transform: 'translate(-50%, -100%) rotate(180deg)', // Center the image and rotate
        overflow: 'hidden',
      }}>
        <img src={dd_glyph}  alt="Image 3" style={{
          width: '100%',
          height: 'auto',
		  transition: 'opacity  1s ease-in-out', // Transition for the clip-path		 
          pointerEvents: 'none', // Disable interactions
		  opacity: isVisible ? '100%' : '0',
		  border: 'none'			  
        }} />
      </div>
      <div style={{
        position: 'absolute',
        width: '150px',
        height: 'auto',
        top: '50%', // Center vertically
        left: '56%', // Position to the left
        transform: 'translate(0, -50%) rotate(270deg)', // Center the image and rotate
        overflow: 'hidden',
      }}>
        <img src={dd_glyph}  alt="Image 4" style={{
          width: '100%',
          height: 'auto',
		  transition: 'opacity  1s ease-in-out', // Transition for the clip-path	
          pointerEvents: 'none', // Disable interactions
		  opacity: isVisible ? '100%' : '0',		  
		  border: 'none'			  
        }} />
      </div>
    </div>  
       
    );
  }
}

export { Component };
