import React, { useRef, type HTMLProps, type ReactNode } from "react";
import {
  type AnimatedProp,
  memo,
  Animated,
  FrameOctagon,
  styleFrameClipOctagon,
  Illuminator,
  useBleeps,
  cx,
  useFrameAssembler,
} from "@arwes/react";
import { css } from "@emotion/css";

// import { type BleepNames, theme } from '@/config'
import { BleepNames } from "../bleeps";
import { ThemeColors, useTheme } from "../theme";

// import styles from './button.module.css'

interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  className?: string;
  animated?: AnimatedProp;
  children: ReactNode;
  color?: ThemeColors;
  frame?: "hexagon" | "octagon" | false;
  variant?: "text" | "outlined" | "contained";
}

const buttonStyle = css({
  position: "relative",
  padding: "0px 15px",
  minWidth: "56px",
  cursor: "pointer",

  textTransform: "uppercase",
  lineHeight: "32px",
  fontSize: "16px",
  fontWeight: "600",
  letterSpacing: "0.06em",

  transitionProperty: "color opacity",
  transitionDuration: "0.5s",
  transitionTimingFunction: "ease-out",
  ".button-back": { opacity: 0.7 },
  ".button-content": {
    position: "relative",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "none",
  },
  ":hover, &--active": { color: "white" },
  ":hover": {
    ".button-back": { opacity: 1 },
  },
});

export const Button = memo(
  ({
    className,
    color = "secondary",
    frame = "hexagon",
    animated,
    children,
    href,
    variant = "outlined",
    ...otherProps
  }: ButtonProps) => {
    const { spacing, palette } = useTheme();
    const themeColor = palette[color]?.main || palette[color];

    const [bgAlpha, lineAlpha] =
      variant === "contained" ? [0.6, 1] : [0.1, 0.5];

    const bleeps = useBleeps<BleepNames>();

    // todo not work
    // const frameRef = useRef<SVGSVGElement>(null);
    // useFrameAssembler(frameRef);

    return (
      <Animated<HTMLButtonElement>
        {...otherProps}
        as={href ? "a" : "button"}
        href={href}
        className={cx(
          buttonStyle,
          //   "group flex",
          //   "uppercase font-cta text-size-10",
          //   "select-none cursor-pointer transition-[color] ease-out duration-200",
          //   "text-secondary-main-2",
          //   "xl:text-size-9",
          //   "hover:text-secondary-high-2",
          // styles.root,
          className
        )}
        // style={{ padding: `0 ${spacing(4)}px` }}
        animated={[
          "fade",
          ...(Array.isArray(animated) ? animated : [animated]),
        ]}
        onMouseEnter={() => {
          bleeps.hover?.play();
        }}
        onClick={(event) => {
          otherProps.onClick?.(event);
          bleeps.click?.play();
        }}
      >
        {frame === "hexagon" && (
          <>
            <FrameOctagon
              // ref={frameRef}
              className="button-back"
              style={{
                filter: `drop-shadow(0 0 ${spacing(2)} ${themeColor(3)})`,
                // @ts-expect-error css variables
                "--arwes-frames-bg-color": themeColor(2, { alpha: bgAlpha }),
                "--arwes-frames-line-color": themeColor(2, {
                  alpha: lineAlpha,
                }),
              }}
              leftBottom={false}
              rightTop={false}
              squareSize={spacing(2)}
            />
            <Illuminator
              style={{
                clipPath: styleFrameClipOctagon({
                  leftBottom: false,
                  rightTop: false,
                  squareSize: spacing(2),
                }),
              }}
              size={spacing(60)}
              color={themeColor(3, { alpha: 0.15 })}
            />
          </>
        )}
        {frame === "octagon" && (
          <>
            <FrameOctagon
              // ref={frameRef}
              className="button-back"
              style={{
                filter: `drop-shadow(0 0 ${spacing(2)} ${themeColor(3)})`,
                // @ts-expect-error css variables
                "--arwes-frames-bg-color": themeColor(2, { alpha: bgAlpha }),
                "--arwes-frames-line-color": themeColor(2, {
                  alpha: lineAlpha,
                }),
              }}
              squareSize={spacing(1)}
            />
            <Illuminator
              style={{
                clipPath: styleFrameClipOctagon({ squareSize: spacing(1) }),
              }}
              size={spacing(60)}
              color={themeColor(3, { alpha: 0.15 })}
            />
          </>
        )}
        <div
          className="button-content"
          // className={cx(
          //   "relative",
          //   "flex-1 flex flex-row justify-center items-center",
          //   "gap-2 px-6 leading-[2rem]",
          //   "xl:px-8"
          //   //   styles.content
          // )}
        >
          {children}
        </div>
      </Animated>
    );
  }
);
