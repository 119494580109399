import { PropsWithChildren } from "react";
import { type AnimatedProp, Animated, cx, useBleeps, Text } from "@arwes/react";
import { css } from "@emotion/css";

import { BleepNames } from "../bleeps";
import { theme } from "../../../theme";

type MenuProps = {
  className?: string;
  animated?: AnimatedProp;
};

const listStyle = css({
  //   display: "grid",
  //   margin: 0,
  //   padding: 0,
  //   gridAutoFlow: "column",
  //   columnGap: "0.25rem",
  //   listStyle: "none",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   fontSize: "0.875rem",
  //   "@media": {
  //     "(min-width: 900px)": {
  //       fontSize: "1rem",
  //     },
  //   },
  listStyle: "none",
  margin: 0,
  padding: 0,
  position: "relative",
  //   paddingTop: "8px",
  //   paddingBottom: "8px",
});
export const List = ({
  className,
  animated,
  children,
}: PropsWithChildren<MenuProps>) => {
  return (
    <Animated as="ul" className={cx(listStyle, className)} animated={animated}>
      {children}
    </Animated>
  );
};

// todo
List.Subheader = ({ children }: PropsWithChildren<unknown>) => (
  <div>{children}</div>
);

const listItemStyle = css({
  //   position: "relative",
  //   display: "grid",
  //   gridAutoFlow: "column",
  //   columnGap: "0.5rem",
  //   justifyContent: "center",
  //   alignItems: "center",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  position: "relative",
  textDecoration: "none",
  width: "100%",
  boxSizing: "border-box",
  textAlign: "left",
  //   background: "pink",
});
List.Item = ({
  className,
  animated,
  active,
  children,
}: PropsWithChildren<{
  className?: string;
  animated?: AnimatedProp;
  active?: boolean;
}>) => {
  const bleeps = useBleeps<BleepNames>();

  return (
    <Animated
      as="li"
      className={cx(
        listItemStyle,
        active && `${listItemStyle}--active`,
        className
      )}
      animated={animated}
      onClick={() => bleeps.click?.play()}
    >
      {children}
    </Animated>
  );
};

const listItemButton = css({
  padding: "8px 16px",
  flexGrow: 1,
  textAlign: "inherit",
  ":hover": { background: theme.palette.primary.main(3, { alpha: 0.15 }) },
  background: 'transparent',
  border: 'none',
  outline: 'none',
  transition: 'color 250ms ease-out',
  cursor: 'pointer',
  color: theme.color.tertiary.main,
  fontFamily:
        'ui-monospace, SFMono-Regular, Menlo, "Roboto Mono", monospace',

});
List.ItemButton = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <button className={cx(listItemButton, "list-item-button")}>
      {children}
    </button>
  );
};

const listItemText = css({
  padding: "4px 0",
});
List.ItemText = ({ primary }: { primary: string }) => {
  return (
    <div className={cx(listItemText, "list-item-text")}>
      <Text as="span">{primary}</Text>
    </div>
  );
};

const listItemIcon = css({
  minWidth: "56px",
  color: "rgba(0, 0, 0, 0.54)",
  flexShrink: 0,
  display: "inline-flex",
});
List.ItemIcon = ({ primary }: PropsWithChildren<{ primary: string }>) => {
  return <div className={cx(listItemIcon, "list-item-icon")}>{primary}</div>;
};
