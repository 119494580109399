import { rgba, lighten } from 'polished';

const styles = theme => ({
  positioned: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0
  },
  root: {
    composes: '$positioned',
    position: 'fixed',
    zIndex: 0,
    backgroundColor: theme.color.background.dark
  },
  patterns: {
    composes: '$positioned',
    zIndex: 0
  },
  light1: {
    composes: '$positioned',
    zIndex: 0,
    backgroundImage: 'radial-gradient(' + rgba(45, 0, 106, 0.3) + ' 25%, transparent)',
    opacity : 0
  },
  line1Container: {
    composes: '$positioned',
    zIndex: 1
  },
  line1: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: 0,
    backgroundColor: theme.color.background.main,
    boxShadow: `0 0 1px ${rgba(theme.color.background.main, theme.color.alpha)}`,
    opacity: 0
  },
  svgContainer: {
    composes: '$positioned',
    zIndex: 2,
    display: 'block',
    width: '100%',
    height: '100%'
  },
  dotLinesContainer: {
    opacity:  0 
  },
  dotLine: {
    stroke: theme.color.background.light,
    strokeWidth: 0
  },
  line2: {},
  line3: {},
  circuitContainer: {},
  circuit: {
    opacity: props => props.energy.exited ? 0 : 1
  },
  circuitLine: {
    fill: 'none',
    stroke: "#00cbf420",
    strokeWidth: 1
  },
  circuitLineLight: {
    fill: 'none',
    stroke: "#00cbf4ff",
    strokeWidth: 1,
    opacity: 1
  },
  circuitDot: {
    fill: lighten(theme.color.accent / 4, theme.color.secondary.dark),
    opacity: props => props.energy.entered ? 1 : 0
  },
  circuitDotStart: {},
  circuitDotEnd: {},
  content: {
    composes: '$positioned',
    zIndex: 1,
    display: 'flex'
  }
});

export { styles };
