import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Text } from '../Text';
import { Link } from '../Link';
import { Animated, Animator} from "@arwes/react";
import   { Text as TextAnimated } from  "@arwes/react";

class Component extends React.Component {
  static displayName = 'Legal';

  static propTypes = {
    theme: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    energy: PropTypes.object.isRequired,
    audio: PropTypes.object.isRequired,
    sounds: PropTypes.object.isRequired,
    className: PropTypes.any,
    opaque: PropTypes.bool,
    onLinkStart: PropTypes.func,
    onLinkEnd: PropTypes.func
  };

  render () {
    const {
      theme,
      classes,
      energy,
      audio,
      sounds,
      className,
      opaque,
      onLinkStart,
      onLinkEnd,
      ...etc
    } = this.props;
    const { animate, duration } = energy;
    const show = energy.entering || energy.entered;

    return (
      <p
        className={cx(classes.root, className)}
        {...etc}
      >
	  
      <Animator duration={{ enter: 3 }}>
	     
		  <TextAnimated
			as="div"
			manager="decipher"
			easing="outSine"
			fixed
		  >
		  <Text animation={{ animate, show, duration }} stableTime>
			DAWNDRUMS SARL
			 </Text>
	      </TextAnimated>
      </Animator>
        <Link
          className={classes.link}
          href='https://github.com/dawndrums'
          target='github'
          onMouseEnter={() => sounds.hover.play()}
          onLinkStart={onLinkStart}
          onLinkEnd={onLinkEnd}
        >
        </Link>
      </p>
    );
  }
}

export { Component };
