import {
  cx,
  FrameNefrex,
  FrameOctagon,
  Illuminator,
  styleFrameClipOctagon,
  Octagon,
} from "@arwes/react";
import { CSSProperties, PropsWithChildren } from "react";
import { css } from "@emotion/css";

import { theme, useTheme } from "../theme";

type PaperProps = {
  className?: string;
  bgColor?: string;
  lineColor?: string;
  style?: CSSProperties;
  variant?: "hexagon" | "octagon" | "standard" | "noframe";
};

const paperStyle = css({
  position: "relative",

  transitionProperty: "all",
  transitionDuration: "0.5s",
  transitionTimingFunction: "ease-out",

  // clipPath: styleFrameClipOctagon({ squareSize: "1rem" }), // todo
  ".paper-content": {
    position: "relative",
    zIndex: 2,
    // clipPath: styleFrameClipOctagon({ squareSize: "1rem" })
  },
  // ".paper-back": { zIndex: -1 },
  ".paper-frame": {
    position: "absolute",
    inset: 0,
    display: "block",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    zIndex: 0,
  },
  ".paper-frame-standard": {
    borderWidth: "1px",
    borderStyle: "solid",
    background: theme.palette.background.paper(9, { alpha: 0.7 }),
    borderColor: theme.palette.primary.main(5),
  },
  ":hover .paper-frame-standard": {
    background: theme.palette.background.paper(9),
  },
  // hexagon
  ".paper-frame-hexagon-clip": {
    "--arwes-frames-bg-color": theme.palette.background.paper(9, {
      alpha: 0.7,
    }),
    "--arwes-frames-line-color": theme.palette.primary.main(5),
    clipPath: styleFrameClipOctagon({
      squareSize: 12,
      leftBottom: false,
      rightTop: false,
    }),
  },
  // hexagon
  ".paper-frame-octagon-clip": {
    "--arwes-frames-bg-color": theme.palette.background.paper(9, {
      alpha: 0.7,
    }),
    "--arwes-frames-line-color": theme.palette.primary.main(5),
    clipPath: styleFrameClipOctagon({ squareSize: 12 }),
  },
  ":hover .paper-frame-hexagon-clip": {
    background: theme.palette.background.paper(9),
  },

  ".paper-frame-illuminator": { zIndex: 1 },
});

export const Paper = ({
  className,
  children,
  bgColor,
  lineColor,
  style,
  variant = "hexagon",
}: PropsWithChildren<PaperProps>) => {
  const { palette, spacing } = useTheme();

  return (
    <div
      className={cx(paperStyle, className)}
      style={{ padding: spacing(1), ...style }}
    >
      {/* {variant === "octagon" && (
        <FrameOctagon
          className="paper-back"
          style={{
            // @ts-expect-error css variables
            "--arwes-frames-bg-color":
              bgColor || palette.background.paper(9, { alpha: 0.7 }),
            // palette.primary.main(9), //"hsl(180, 75%, 10%)",
            "--arwes-frames-line-color": lineColor || palette.primary.main(5), //"hsl(180, 75%, 50%)",
          }}
        />
      )} */}
      {variant === "standard" && (
        <div
          className={cx("paper-frame", "paper-frame-standard")}
          style={{ background: bgColor, borderColor: lineColor }}
        />
      )}
      {variant === "hexagon" && (
        <div
          className={cx("paper-frame", "paper-frame-hexagon-clip")}
          // className={cx(classes.frameElement, classes.frameHexagonClip)}
        >
          <Illuminator
            // className={classes.frameHexagonIlluminator}
            className="paper-frame-illuminator"
            color="hsl(60 50% 90% / 8%)"
            size={200}
          />
          <FrameOctagon
            squareSize={12}
            leftBottom={false}
            rightTop={false}
            style={{
              // @ts-expect-error css variables
              "--arwes-frames-bg-color": bgColor,
              "--arwes-frames-line-color": lineColor, //"hsl(180, 75%, 50%)",
            }}
          />
        </div>
      )}
      {variant === "octagon" && (
        <div
          className={cx("paper-frame", "paper-frame-octagon-clip")}
          // className={cx(classes.frameElement, classes.frameHexagonClip)}
        >
          <Illuminator
            // className={classes.frameHexagonIlluminator}
            className="paper-frame-illuminator"
            color="hsl(60 50% 90% / 8%)"
            size={200}
          />
          <FrameOctagon
            squareSize={12}
            style={{
              // @ts-expect-error css variables
              "--arwes-frames-bg-color": bgColor,
              "--arwes-frames-line-color": lineColor, //"hsl(180, 75%, 50%)",
            }}
          />
        </div>
      )}
      <div className="paper-content">{children}</div>
      {/* <div className="paper-content">{children}</div> */}
    </div>
  );
};
